//colors

$middleRed: #CB8589;
$davysGray: #484A47;
$polishedPine: #68A691;
$pacificBlue: #22AED1;
$orangeSoda: #FC5130;

body,
html,
#root {
  width: 100%;
  height: 100%;
}

body {
  background-color: #efefef;
  color: $davysGray;
  margin: 0px;
  padding: 0px;
}

.ucmas-loading-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  
  background-color: #efefef;

  z-index: 1000000000;

  display:flex;
  
  justify-content: center;
  align-items: center;
  
}

.ucmas-app-container {
  padding: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
  overflow: hidden;
  text-align: center;
  
  .ucmas-logo-container {
    position: fixed;
    left: 20px;
    bottom: 20px;

    img {
      height: 30px;
      opacity: 0.5;
      transition: 0.3s opacity;

      &:hover {
        opacity: 1;
      }
    }
  }

  .prepare-block {
    font-size: 140px;
    margin: 50px 0px;
    color: #F25757;
    text-decoration: underline;
  }

  .contrlos {
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    justify-content: center;
    align-items: flex-end;
    position: relative;

    margin: 30px auto;

    .button {
      margin: 0px 4px;
    }

    .timer {
      position: absolute;
      width: 50px;
      height: 50px;



      &.left {
        left: -60px;
        top: -5px;
      }

      &.right {
        right: -60px;
        top: -5px;
      }
    }

    .hidden-timer {
      position: absolute;
      left: 50%;

      width: 100px;

      margin-left: -50px;

      top: 50px;

      font-size: 18px;
      font-weight: bold;
      font-family: 'Courier New', Courier, monospace;

      color: $orangeSoda;
    }
  }

  .float-button {
    position: fixed;

    &.top-left {
      left: 10px;
      top: 10px;
    }

    &.top-right {
      right: 10px;
      top: 10px;
    }

    &.bottom-left {
      left: 10px;
      bottom: 10px;
    }

    &.bottom-right {
      right: 10px;
      bottom: 10px;
    }

  }

  //todo - make it grid
  .example-container {
    height: 80vh;
    display: block;
    border: 10px solid $middleRed;
    margin: 0px auto;
    max-width: 300px;
    width: 70%;
    display: flex;
    flex-direction: column;
    background-color: $middleRed;
    align-items: space-between;
    transition: opacity 0.5s;
    overflow: hidden;

    &.hidden {
      opacity: 0;
    }

    &.type-2 {
      width: 400px;

      .numbers {
        grid-template-rows: 1fr 1fr;
      }

      .number {
        font-size: 140pt;
      }
    }

    &.type-3 {
      width: 300px;

      .numbers {
        grid-template-rows: 1fr 1fr 1fr;
      }

      .number {
        font-size: 100pt;
      }
    }

    &.type-4 {
      width: 240px;

      .numbers {
        grid-template-rows: 1fr 1fr 1fr 1fr;
      }

      .number {
        font-size: 80pt;
      }
    }

    &.type-5 {
      width: 200px;

      .numbers {
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
      }

      .number {
        font-size: 50pt;
      }
    }

    .header {
      font-weight: bold;
      font-size: 30px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
    }

    .numbers {
      height: 100%;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      gap: 10px;
      justify-content: center;
      

      .number {
        line-height: 1;
        font-weight: bold;
        text-align: center;
        background-color: white;

        align-items: center;
        display: flex;
        justify-content: center;
      }
    }
  }

  .overflow-container {
    overflow: auto;
  }

  .result-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;

    &.type-1 {
      grid-template-columns: 1fr;
    }

    &.type-2 {
      grid-template-columns: 1fr 1fr;
    }

    &.type-3 {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &.type-4 {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    &.type-5 {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    &.type-6 {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    &.type-7 {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    &.type-8 {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    &.type-9 {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }


    .cell {
      width:100px;
      height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 10px;
      background-color: $middleRed;
      box-sizing: border-box;
      transition: background-color .3s;

      &:hover {
        cursor: pointer;
        background-color: $pacificBlue;
      }

      &.selected {
        background-color: #62C370;
      }

      .number {
        text-align: center;
        height: 30px;
        line-height: 10px;
        color: #fff;
        font-weight: bold;
      }

      .value {
        background-color: #fff;
        display: flex;
        height: 100%;
        width: 100%;

        justify-content: center;
        align-items: center;

        font-size: 26px;
        font-weight: bold;
      }
    }
  }
}

.ucmas-drawer-container {
  display: flex;
  height: 100%;
  flex-direction: column;

  .add-block {
    padding: 0px 20px 20px;
    margin-bottom: 20px;

    .label {
      margin-bottom: 5px;
      font-weight: bold;
    }

    border-bottom: 2px solid #ededed;

  }

  .ucmas-examples-list {
    height: 100%;
    overflow-y: auto;

    .clear-action-container {
      text-align: center;
      margin-top: 10px;
    }
    .ant-list-item {
      padding: 4px 2px
    }

    .list-row {
      width: 100%;
      position: relative;

      .example {
        padding: 0px 30px 0px 30px;
        transition: all 0.3s;

        .number {
          transition: all 0.3s;

          min-width: 30px;
          height: 30px;
          line-height: 26px;
          text-align: center;

          vertical-align: middle;
          display: inline-block;
          margin-right: 4px;
          font-size: 18px;
          font-weight: bold;
          padding: 2px 4px;
          box-sizing: border-box;


          background-color: #ededed;
        }

        &:hover {
          background-color: rgba(85, 134, 140, 0.3);
          cursor: pointer;

          .number {
            background-color: rgba(85, 134, 140, 0.6);
          }
        }

        &.active {
          background-color: rgba(161, 232, 135, 0.3);

          .number {
            background-color: rgba(161, 232, 135, 0.6);
          }
        }

      }


      .position {
        position: absolute;
        width: 30px;
        line-height: 30px;
        margin-left: 5px;
        font-size: 12px;
        color: #aeaeae;

        &:after {
          content: '.';
        }
      }

      .actions {
        position: absolute;
        right: 0px;
        top: 3px;
      }
    }
  }

  .source-list {
    .row {
      flex: 1;
      display: flex;
      flex-direction: row;

      .info {
        flex: 1;
        width: 100%;

        .name {
          font-weight: bold;
        }

        .description {
          padding-left: 5px;
          color: #898989;
        }
      }

      .action {
        width: 50px;
        border-left: 1px solid #dedede;
        justify-content: center;
        align-items: center;
        display: flex;
        transition: all 0.3s;

        &:hover {
          background-color: rgba(161, 232, 135, 0.6);
          cursor: pointer;
        }
      }
    }
  }
}



.no-padding {
  .ant-drawer-body {
    padding: 0px !important;
  }
}

@media (max-height: 700px) {
  .type-3 .number {
    font-size: 70pt !important;
  }

  .type-2 .number {
    font-size: 80pt !important;
  }

  .type-4 .number {
    font-size: 50pt !important;
  }

  .type-5 .number {
    font-size: 40pt !important;
  }
}


@media (max-height: 500px) {
  
  .type-3 .number {
    font-size: 50pt !important;
  }

  .type-4 .number {
    font-size: 40pt !important;
  }

  .type-5 .number {
    font-size: 30pt !important;
  }
}